const PRODUCTION_CONFIG = {
  pathPrefix: '/docs',
  siteUrl: 'https://area-monitoring.sinergise.com',
  baseCanonicalUrl: 'https://area-monitoring.sinergise.com/docs',
  fathomTracking: true,
  showDrafts: false,
  search: {
    enabled: true,
    indexName: 'prod_docs',
  },
}

const STAGE_CONFIG = {
  pathPrefix: '/am-docs',
  siteUrl: 'https://webdev-fe.sinergise.com',
  baseCanonicalUrl: 'https://area-monitoring.sinergise.com/docs',
  fathomTracking: false,
  showDrafts: true,
  search: {
    enabled: true,
    indexName: 'stage_docs',
  },
}

const ACTIVE_CONFIG = process.env.GATSBY_DEPLOY_ENV === "production" ? PRODUCTION_CONFIG : STAGE_CONFIG

const config = {
  gatsby: {
    ...ACTIVE_CONFIG,
    trailingSlash: true,
  },
  fathomTracking: {
    enabled: ACTIVE_CONFIG.fathomTracking,
    url: process.env.GATSBY_FATHOM_TRACKING_URL,
    dataSite: process.env.GATSBY_FATHOM_TRACKING_DATASITE,
  },
  header: {
    logo: '',
    logoLink: '/',
    title: '',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: ACTIVE_CONFIG.search.enabled,
      indexName: ACTIVE_CONFIG.search.indexName,
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
      minNumOfCharacters: 3,
      delayBeforeSearch: 200,
    },
  },
  sidebar: {
    forcedNavOrder: [],
    links: [],
    frontline: false,
    title: '',
  },
  rightSidebar: {
    maxDepth: 3,
  },
  siteMetadata: {
    title: 'Gatsby Gitbook Boilerplate | Hasura',
    description: 'Documentation built with mdx. Powering hasura.io/learn ',
    ogImage: 'https://www.sentinel-hub.com/img/og-image/satellite.png',
  },
};

module.exports = config;
