import { css } from '@emotion/react';

const customStyles = css`
  table {
    display: table;
    table-layout: fixed;
    width: auto;
    width: -webkit-fill-available;
    width: -moz-available;

  }
  table tr th {
    width: auto;
  }
  .gatsby-resp-image-wrapper {
    margin-left: 0!important;
    margin-right: 0!important;
  }
`;

export const styles = [customStyles];
