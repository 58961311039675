import React, { Component } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Search } from '@styled-icons/fa-solid/Search';

const SearchIcon = styled(Search)`
  width: 1em;
  pointer-events: none;
  margin-right: 10px;
  position: absolute;
  left: 15px;
  color: #2fd2c5;
`;

const focus = (props) => css`
  background: white;
  color: ${(props) => props.theme.darkBlue};
  cursor: text;
  width: 5em;
  + ${SearchIcon} {
    color: ${(props) => props.theme.darkBlue};
    margin: 0.3em;
  }
`;

const collapse = (props) => css`
  width: 0;
  cursor: pointer;
  color: ${(props) => props.theme.lightBlue};
  + ${SearchIcon} {
    color: white;
  }
  ${(props) => props.focus && focus()}
  margin-left: ${(props) => (props.focus ? `-1.6em` : `-1em`)};
  padding-left: ${(props) => (props.focus ? `1.6em` : `1em`)};
  ::placeholder {
    color: ${(props) => props.theme.gray};
  }
`;

const expand = (props) => css`
  background: ${(props) => props.theme.veryLightGray};
  width: 6em;
  margin-left: -1.6em;
  padding-left: 1.6em;
  + ${SearchIcon} {
    margin: 0.3em;
  }
`;

const collapseExpand = (props) => css`
  ${(props) => (props.collapse ? collapse() : expand())}
`;

const Input = styled.input`
  outline: none;
  border: none;
  font-size: 1em;
  background: white;
  transition: ${(props) => props.theme.shortTrans};
  border-radius: ${(props) => props.theme.smallBorderRadius};
  {collapseExpand}
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-left: 15px;
  }
`;

class SearchBoxInput extends Component {
  timerId = null;

  state = {
    value: this.props.currentRefinement,
  };

  onChangeDebounced = (event) => {
    const { refine, delay, minNumOfCharacters } = this.props;
    const value = event.currentTarget.value;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      if (value.length >= minNumOfCharacters) {
        refine(value);
      } else {
        refine('');
      }
    }, delay);

    this.setState(() => ({
      value,
    }));
  };

  preventSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    const { value } = this.state;

    return (
      <Form className={'formElement'} onSubmit={this.preventSubmit}>
        <SearchIcon />
        <Input
          className={'searchInput '}
          type="text"
          placeholder="Search"
          aria-label="Search"
          value={value}
          onChange={this.onChangeDebounced}
          {...this.props}
        />
      </Form>
    );
  }
}

export default connectSearchBox(SearchBoxInput);
