/* BEGIN: redirects */
import config from './config'
import redirectsFromGatsby from './src/webpack/gatsby-redirect-loader!./redirects/from_gatsby.csv'

function tryRedirect(redirectMap) {
  const locationHash = window.location.hash
  if (locationHash) {
    const r = redirectMap[locationHash] || redirectMap[locationHash.split('?')[0]]
    if (r) {
      window.location = config.gatsby.pathPrefix + r
    }
  }
}

function tryRedirectGatsby() {
  const locationPathnameWithoutPrefix = window.location.pathname.replace(config.gatsby.pathPrefix, '')
  if (redirectsFromGatsby[locationPathnameWithoutPrefix]) {
    tryRedirect(redirectsFromGatsby[locationPathnameWithoutPrefix])
  }
}

tryRedirectGatsby()
/* END: redirects */
